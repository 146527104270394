<template>
  <div id="app">
    <el-container>
      <el-header>
        <MyHeader/>
      </el-header>
      <el-main>
        <div class="main">
          <div class="container">
            <router-view/>
          </div>
        </div>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import MyHeader from './components/Header.vue'

export default {
  name: 'App',
  components: {
    MyHeader
  }
}
</script>

<style>
* {
  padding: 0;
  margin: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* 清空边距 */
  padding: 0 !important;
  margin: 0 !important;
}
.el-header {
    z-index: 5;
    height: 64px;
    min-height: 64px;
    box-shadow: 0 3px 12px 0 rgba(0,0,0,.08);
    background: #fff;
    width: 100%;
    position: fixed;
    top: 0;
  }
  
  .el-main {
    background-color: #E9EEF3;
    color: #333;
    text-align: center;
    overflow: auto;
    height: 100vh;
    padding-bottom: 12px;
    padding: 0 !important;
    padding-top: 76px !important;
  }

.main {
  padding-left: calc(100vw - 100%);
  /* background-color: aqua; */
}

.container {
    margin: 0 auto;
    max-width: 1200px;
    min-width: 1200px;
    /* background-color: bisque; */
}
</style>
