/*
 * @Author: Zhiqing 1775840762@qq.com
 * @Date: 2024-03-02 23:08:37
 * @LastEditors: Zhiqing 1775840762@qq.com
 * @LastEditTime: 2024-03-04 14:05:16
 * @FilePath: \hw-visual\src\router\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
 
Vue.use(VueRouter)
 
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            name: 'Leisi',
            meta: {
              title: '蕾丝数据'
            },
            component: () => import('../views/LeisiData.vue')
        },
        {
            path: '/leisi',
            name: 'Leisi',
            meta: {
              title: '蕾丝数据'
            },
            component: () => import('../views/LeisiData.vue')
        },
        {
            path: '/huaxian',
            name: 'Huaxian',
            meta: {
              title: '化纤数据'
            },
            component: () => import('../views/HuaxianData.vue')
        },
        {
            path: '/leisi-label',
            name: 'LeisiLabel',
            meta: {
              title: '蕾丝异常类别'
            },
            component: () => import('../views/LeisiLabel.vue')
        },
        {
            path: '/huaxian-label',
            name: 'HuaxianLabel',
            meta: {
              title: '化纤异常类别'
            },
            component: () => import('../views/HuaxianLabel.vue')
        },
        {
            path: '/some-ideas',
            name: 'SomeIdea',
            meta: {
              title: '一些想法'
            },
            component: () => import('../views/SomeIdea.vue')
        },
        {
          path: '/leisi-oneclass',
          name: 'LeisiOneClass',
          meta: {
            title: '蕾丝单异常'
          },
          component: () => import('../views/LeisiOneClass.vue')
        }
    ]
})

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = `${to.meta.title} - 华为难题数据集`
  } else {
    document.title = '华为难题数据集'
  }
  next()
})
 
export default router